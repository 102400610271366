<template>
  <div class="page_bg" style="height: 25rem;background: rgba(33, 34, 52, 1);">
    <div class="vip_linear-gradient">
      <div v-if="type === 2" class="vip_login">
        <van-form style="padding: 0.45rem;">
          <van-field v-model="loginForm.mobile" type="tel" name="mobile" placeholder="请输入您的商城注册手机号" maxlength="11"
                     :rules="[{ required: true, message: '' }]"
          />
          <van-field v-model="loginForm.checkCode" type="digit" class="van-field" name="checkCode" placeholder="请输入验证码"
                     maxlength="6" :rules="[{ required: true, message: '' }]" style="margin-top: 0.37rem;"
          >
            <template #extra>
              <van-count-down v-if="isSend" :time="time" format="ss 秒重新发送" style="color: #C2460E;font-size: 0.32rem;"
                              @finish="finish"
              />
              <div v-else class="send_message" style="color: #C2460E;font-size: 0.32rem;" @click="sendMessage">获取验证码
              </div>
            </template>
          </van-field>
          <div class="vip_login_sub">
            <van-image width="6.67rem" height="1.6rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/linkPage/vip_btn.png"
                       @click="onSubmit"
            />
          </div>
        </van-form>
      </div>
      <div v-if="type === 1" style="margin-top: 3rem;">
        <div class="vip_login_sub">
          <van-image width="6.67rem" height="1.6rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/linkPage/vip_btn.png"
                     @click="getVipCard"
          />
        </div>
      </div>
    </div>
    <div class="vip_auth">
      <van-image width="8.51rem" height="5.84rem"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/linkPage/vip_auth.png"
      />
    </div>
  </div>
</template>

<script>
import './index.scss'
import { sms_send } from '@/api/user'
import { vip_receiveCode, receiveAutomatic} from '@/api/vip'
export default {
  name: 'Vip',
  data() {
    return {
      isSend: false,
      loading: false,
      time: 60 * 1000,
      loginForm: {
        mobile: '', checkCode: '', openId: '', platformType: 'wxmp' // 微信(H5):wxmp 微信:wx
      },
      type: 2 // 1已经登录 2未登录
    }
  },
  computed: {

  },
  created() {
    this.checkLogin()
  },
  methods: {
    backPath() { window.history.back() },
    // 判断登录态
    checkLogin() {
      var openId = localStorage.getItem('openId') || this.$store.getters.openid
      if (openId) { this.type = 1 } else { this.type = 2 }
    },
    // 领取
    getVipCard() {
      receiveAutomatic().then(res => {
        this.$router.push('/user/my')
        this.$toast({ message: '领取成功！', duration: 1 * 1000 })
      })
    },
    // 点击登录
    onSubmit(values) {
      this.loading = true
      vip_receiveCode({ checkCode: this.loginForm.checkCode, mobile: this.loginForm.mobile }).then(res => {
        this.$toast({ message: '领取成功！', duration: 1 * 1000 })
        this.$utils.storeUserInfo(this.$store, res.data)
        this.$utils.localLogin(this, res.data, 'login_vip')
      })
    },

    // 发送短信
    sendMessage() {
      if (this.loginForm.mobile.length === 11) {
        const parms = { mobile: this.loginForm.mobile, type: 'vip' }
        sms_send(parms).then(res => {
          this.$toast({ message: '已发送！', duration: 3 * 1000 })
          this.isSend = true
        })
      } else {
        this.$toast({ message: '请输入手机号', position: 'top', duration: 3 * 1000 })
      }
    },
    // 倒计时结束触发
    finish() { this.isSend = false }

  }
}
</script>
<style lang="scss" scoped>
// html {
//   background: rgba(33, 34, 52, 1) !important;
// }
</style>
